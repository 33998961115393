<template>
    <div class="enquipmentsList" :style="enquipmentsList">
        <common-nav-bar title="我的治疗仪"></common-nav-bar>
        <!--        <van-cell-group class="bgNo userCenterInfo">-->
        <!--            <van-cell center label="1年用户" to="/patient/my/editUser">-->
        <!--                <template #icon>-->
        <!--                    <van-image class="thumbnailImg mr10" :src="user.wxHeadimgurl" />-->
        <!--                </template>-->
        <!--                <template #title>-->
        <!--                    <span class="custom-title">{{ user.wxNickname }}</span>-->
        <!--                    <span class="userTag"  :style="tagBg">Lv.1</span>-->
        <!--                    &lt;!&ndash; <van-tag size="mini" ></van-tag> &ndash;&gt;-->
        <!--                </template>-->
        <!--                <template #right-icon>-->
        <!--                    <van-image class="userSetting mr10" :src="userSetting" />-->
        <!--                </template>-->
        <!--            </van-cell>-->
        <!--        </van-cell-group>-->
        <div class="instrumentCard">
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">
                    <van-image class="cardIcon" width="14" :src="phoneIcon" />我的治疗仪
                </div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;共{{ equipments.length }}台</div>
                <div class="cardTileRight cellFlex-1">
                    <van-button
                        round
                        size="mini"
                        plain
                        color="#4AB8AB"
                        icon="play"
                        type="info"
                        @click="showAddPopup = true"
                    >
                        新增治疗仪
                    </van-button>
                </div>
            </div>
            <div class="cardBody" v-for="item in equipments" :key="item.sn">
                <div class="cell">
                    <div class="cardBodyLeft">ID</div>
                    <div class="cardBodyRight cellFlex-1">{{ item.sn }}</div>
                </div>

                <div class="userInfo">
                    <van-cell-group>
                        <van-cell center>
                            <template #icon>
                                <van-image :src="item.patientWxHeadimgurl || userPhoto" />
                            </template>
                            <template #title>
                                {{ item.patientName }}
                                <van-icon
                                    :name="curSnUser.patientSex === 1 ? sexNv : sexNan"
                                    class="sex-icon ml10"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>
        <van-popup
            class="popBg"
            :style="popBg"
            v-model="showAddPopup"
            :close-on-click-overlay="false"
        >
            <div class="equipmentsForm">
                <h2 class="bgNo textC">请向治疗仪用户获取验证码</h2>
                <van-field
                    class="bgNo"
                    center
                    v-model="waitAddSn"
                    label="治疗仪编号:"
                    placeholder="请输入治疗仪编号"
                />
                <van-field
                    class="bgNo"
                    center
                    v-model="waitAddVerifyCode"
                    label="验证码:"
                    placeholder="请输入验证码"
                >
                    <template #button>
                        <van-button
                            size="mini"
                            type="primary"
                            round
                            color="#4AB8AB"
                            :disabled="sendText !== '发送验证码'"
                            @click="sendBindEquipmentVerifyCode"
                            >{{ sendText }}
                        </van-button>
                    </template>
                </van-field>
                <div
                    style="
                        display: flex;
                        justify-content: center;
                        margin-top: 4px;
                        margin-bottom: 4px;
                    "
                >
                    <van-button
                        class="plr40"
                        color="#4ab8ab"
                        type="primary"
                        round
                        size="small"
                        @click="addEquipment"
                        >获取授权</van-button
                    >
                </div>
                <div class="equipmentsCancel" @click="showAddPopup = false">取消</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    addEquipment,
    delEquipment,
    getMyEquipments,
    sendBindEquipmentVerifyCode,
} from '@/services/user';
import { toast } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    name: 'myEquipments',
    components: { CommonNavBar },
    data() {
        return {
            user: {},
            userImg: [require('@/assets/images/userImg.png')],
            userSetting: require('@/assets/images/shezhi.png'),
            phoneIcon: require('@/assets/images/phoneIcon.png'),
            popBg: {
                backgroundImage: 'url(' + require('@/assets/images/popBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                borderRadius: '8px',
                width: '80%',
            },
            enquipmentsList: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
            equipments: [],
            showAddPopup: false,
            sendText: '发送验证码',
            waitAddSn: '',
            waitAddVerifyCode: '',

            curSnUser: {},
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),

            tagBg: {
                backgroundImage: 'url(' + require('@/assets/images/tabBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            },
        };
    },
    async created() {
        await this.getMyEquipments();
    },
    methods: {
        async getMyEquipments() {
            const { code, message, data } = await getMyEquipments();
            if (!code) {
                return toast(message);
            }
            this.equipments = data;
        },

        async sendBindEquipmentVerifyCode() {
            if (!this.waitAddSn) {
                return toast('请输入治疗仪编号');
            }
            const { code, message } = await sendBindEquipmentVerifyCode({ sn: this.waitAddSn });
            if (!code) {
                return toast(message);
            }
            let second = 60;
            this.sendText = `${second}s后重新发送`;
            const timer = setInterval(() => {
                second--;
                if (second) {
                    this.sendText = `${second}s后重新发送`;
                } else {
                    this.sendText = '发送验证码';
                    clearInterval(timer);
                }
            }, 1000);
        },

        async addEquipment() {
            if (!this.waitAddSn) {
                return toast('请输入治疗仪编号');
            }
            if (!this.waitAddVerifyCode) {
                return toast('请输入短信验证码');
            }
            const { code, message } = await addEquipment({
                sn: this.waitAddSn,
                verifyCode: this.waitAddVerifyCode,
            });
            if (!code) {
                return toast(message);
            }
            toast('新增成功');
            await this.getMyEquipments();
            this.showAddPopup = false;
        },

        async delEquipment(sn) {
            const { code, message } = await delEquipment(sn);
            if (!code) {
                return toast(message);
            }
        },
    },

    async getMyEquipments() {
        const { code, message, data } = await getMyEquipments();
        if (!code) {
            return toast(message);
        }
        this.equipments = data;
        this.equipmentColumns = this.equipments.map(e => `${e.patientName} ${e.sn}`);
        if (this.equipments.length) {
            this.curSn = localStorage.getItem('curSn');
            if (!this.curSn || !this.equipments.map(e => e.sn).includes(this.curSn)) {
                this.curSn = this.equipments[0].sn;
                localStorage.setItem('curSn', this.curSn);
            }
            this.curSnUser = this.equipments.filter(e => e.sn === this.curSn)[0];
        }
    },

    selectCurEquipment(val) {
        this.curSnUser = this.equipments.filter(e => `${e.patientName} ${e.sn}` === val)[0];
        this.curSn = this.curSnUser.sn;
        localStorage.setItem('curSn', this.curSn);
        this.showEquipmentsPopup = false;
    },
};
</script>

<style scoped>
.userCenterInfo .van-cell {
    background: none;
    padding: 25px;
}

.thumbnailImg {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}

.custom-title {
    font-size: 14px;
    color: #282a2d;
}

.userTag {
    padding: 2px 0px;
    color: #fff;
    margin-left: 5px;
    font-size: 10px;
    width: 45px;
    display: inline-block;
    height: 15px;
    line-height: 17px;
    text-indent: 19px;
    position: relative;
    top: -2px;
}

.userSetting {
    width: 30px;
    height: 30px;
}

.userLinkList {
    background: none;
    margin: 5px;
}

.userLinkList .van-cell {
    border-radius: 8px;
    margin-bottom: 5px;
}

.linkThumbnailImg {
    width: 30px;
    height: 30px;
}

.userLinkList .van-cell__title {
    font-size: 13px;
    color: #4c4c4c;
}

.userCenter {
    height: 100%;
}

.userCenterLink {
    background: #f6f6f8;
    padding: 5px 0;
    height: calc(100% - 110px);
}

.instrumentCard {
    overflow: hidden;
    background: #f6f6f6;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    background: #fff;
    border-radius: 8px;
}

.cardTileLeft {
    position: relative;
}

.cardIcon {
    vertical-align: text-top;
    margin-right: 10px;
}
.cardBody {
    background: #fff;
    border-radius: 8px;
    padding: 1px 0;
    margin-top: 10px;
}
.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
    line-height: 1;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
    position: relative;
}
.cardBody .cardBodyLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -11px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}
.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: left;
    text-indent: 20px;
}

.noInstrument {
    font-size: 12px;
    padding: 15px 16px;
    color: #8d8d8d;
    font-weight: 600;
}

.userInfo .van-cell-group {
    margin: 10px;
    background: #f7f7f7;
}
.userInfo .van-cell {
    background: none;
    padding: 5px 8px;
}

.userInfo .van-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
}
.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}
.equipmentsForm .van-cell::after {
    display: none;
}
.equipmentsCancel {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    color: #4ab8ab;
}
.cardBody {
    border-bottom: 1px solid #f1f1f1;
}
.cardBody:last-child {
    border-bottom: 0px solid #f1f1f1;
}
.cardBodyTip {
    color: #4ab8ab;
    font-size: 14px;
    line-height: 28px;
}
.van-button--mini {
    padding: 0 10px;
}
</style>
